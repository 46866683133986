import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function Frm() {
  return (
    <div
      className="container"
      style={{
        backgroundImage: "url(./photos/feather-340.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: "50px",
      }}
    >
      <div className="container">
        <div
          className="log"
          style={{
            width: "500px",
            padding: "50px 0px",
            margin: "50px 0px 50px 300px",
          }}
        >
          <div class="container">
            <div class="loginform" style={{ textAlign: "center" }}>
              <h1 style={{ color: "#0045ff" }}>
                <b>Login</b>
              </h1>
              <div
                class="jjjjj"
                style={{
                  borderBottom: " 5px solid #34f2c9",
                  margin: "0px 426px",
                }}
              ></div>
            </div>
          </div>
          <br />
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>User Id</Form.Label>
              <Form.Control type="User Id" placeholder="Enter email" />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label style={{ textAlign: "left" }}>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="" />
              <h6 className="ck" style={{ margin: "-20px 0px 0px -350px" }}>
                Check me out
              </h6>
            </Form.Group>
            <Button variant="primary" type="submit">
              Login
            </Button>
            {/* <a href="" style={{marginLeft:"20px",}}> Register</a> */}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Frm;
