import React from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import N1 from "./N1";
import "./About.css";

function Safty() {
  return (
    <>
      <N1 />
      <div>
        <br />
        <div class="ffd">
          <h2 style={{ textAlign: "center" }}>
            <b>
              SAFTEY & SECURITY{" "}
              <span style={{ color: "red" }}>PANELS & SENSORS</span> – SPARTAN
            </b>
          </h2>
          <div
            class="pp"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 246px",
            }}
          ></div>
          <br />
          <br />
          <div class="container">
            <div className="row">
              <div className="col-md-8">
                <h5>
                  <b style={{ textAlign: "left" }}>
                    <li>
                      –SPARTAN Series is UPTL ‘S Premium Category Hybrid[Wired &
                      Wireless] intrusion & Home Automation Control Panel.
                    </li>
                    <li>
                      SPARTAN is a Professional Modern Wireless Security, Safety
                      Control Panel with Optional Home Automation Capabilities.
                      It is Suitable for Residential, Industrial, Banking
                      Customers and Home Automation System in a single Platform
                      with Integrated 4G, IoT, Wireless Dual Mode Wi-Fi Hotspot.
                    </li>
                    <li>
                      Android App to control & Configure. Customized Web-app for
                      Banking & industries.{" "}
                    </li>
                    <li>
                      In-built Hotspot to Connect and Provide internet to all
                      Wi-Fi Appliances like TV, Laptop, Mobile Phone etc.
                    </li>
                    <li>In-built IoT & AI with free Cloud Connectivity.</li>
                    <li>CMS Compatible with SIA DC-9/Customizable Protocl.</li>
                  </b>{" "}
                </h5>
              </div>
            </div>
            <br />

            <div className="dj">
              <img className="dx" src="./DXADJ.jpg" alt="" />
            </div>
            <br />
          </div>
        </div>

        <div className="container">
          {/* <div className="i1">
            <img className="gas" src="./gas.jpg" alt="" />
          </div> */}

          <div className="i2">
            <img className="s3" src="./s3.jpg" alt="" />
          </div>
        </div>
        <br />

        {/* 4 */}
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                className="spa"
                src="spartan.jpg"
                style={{ height: "300px", width: "500px" }}
              ></img>
              <h3>SPARTAN MAIN </h3>
            </div>

            <div className="col-md-6">
              <img
                className="spa"
                src="./PRODUCTS/SPARTAN-MAIN-PCB.jpg"
                style={{ height: "300px", width: "500px" }}
              ></img>
              <h3>SPARTAN-MAIN-PCB</h3>
            </div>
          </div>
        </div>

        {/* e1 */}
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                className="spa"
                src="./PRODUCTS/SPARTAN.jpg"
                style={{ height: "300px", width: "500px" }}
              ></img>
              <h3>SPARTN</h3>
            </div>

            <div className="col-md-6">
              <img
                className="spa"
                src="./PRODUCTS/SPARTAN MAIN-INSIDE.jpg"
                style={{ height: "300px", width: "500px" }}
              ></img>
              <h3>SPARTAN MAIN-INSIDE</h3>
            </div>
          </div>
        </div>

        {/* 4 */}
      </div>
    </>
  );
}

export default Safty;
