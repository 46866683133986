import React from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import N1 from './N1'

function G1() {
  return (
    <>
      <N1 />
      <div className="container">
        <img className="spa" src="./ASD.JPG.png" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img className="spa"
              src="./RKz7HO.jpg"
              style={{ height: "300px", width: "500px" }}
            ></img>
            {/* <h3>SPARTAN MAIN </h3> */}
          </div>

          <div className="col-md-6">
            <img className="spa"
              src="./asdwe.jpg"
              style={{ height: "300px", width: "500px" }}
            ></img>
            {/* <h3>SPARTAN-MAIN-PCB</h3> */}
          </div>
        </div>
      </div>

      <div className="container">
        <img className="spa" src="./t.jpg" />
      </div>
    </>
  );
}

export default G1;
