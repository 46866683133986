import React from "react";
import Card from "react-bootstrap/Card";
import N1 from "./N1";

function Product1() {
  return (
    <>
      <N1 />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Card style={{ width: "32rem" }}>
              <Card.Img variant="top" src="./PRODUCTS/Elite-Box.jpg" />
            </Card>
            <br></br>
            <h2 style={{ color: "blue" }}>Spartan Elite Air</h2>
            <h4 style={{ color: "green" }}>
              Wireless Metal Box with Optional 4Hr /<br></br> 10 hr Backup
            </h4>
            <li>
              Wireless Zones<br></br>- 32, 99 Zones
            </li>
          </div>
          <div className="col-md-6">
            <Card style={{ width: "32rem" }}>
              <Card.Body>
                <Card.Title>
                  <b>WHIRELESS</b>
                </Card.Title>
                <Card.Text>
                  <ul style={{ textAlign: "left", lineHeight: "1.8rem" }}>
                    <li>
                      2G Wired+32 WHIRELESS / Expandable Up to 99 WHIRELESS Zone
                    </li>
                    <li> On Board Graphical LCD & Keypad</li>
                    <li> Very Compact Wireless Alarm Panel</li>
                    <li> ACK based Wireless Sensor Reading</li>
                    <li>
                      Home Kit With Wireless Door Sensor, Vibration Detector,
                      Motion Sensor, Key Fob etc for 2BHK / 3BHK / Villa
                    </li>
                    <li>
                      SHop Kit with Wireless Door Sensor, Vibration Detector,
                      Motion Sensor, Shutter Contact Sensor etc
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
            <br></br>
            <li style={{ textAlign: "left" }}>
              Wired Zones<br></br>- 2 Zones
            </li>
            <li style={{ textAlign: "right", marginTop: "-36px" }}>
              Wireless Zones<br></br>- 32, 99 Zones
            </li>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Card style={{ width: "32rem" }}>
                <Card.Img variant="top" src="./PRODUCTS/Elite-Box.jpg" />
              </Card>
            </div>
            <div className="col-md-6">
              <ul style={{ textAlign: "left", lineHeight: "2.1rem" }}>
                <li>Intrusion Prevention System</li>
                <li>Fire Panel and Smoke Detectors</li>
                <li>Gas, Water and Oil Detectors</li>
                <li>PIR, Luxand IOT Enabled Sensors</li>
                <li>Wide RANGE of Safety and security Features</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product1;
