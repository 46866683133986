import logo from "./logo.svg";
import "./App.css";
import Uptl from "./Components/Uptl";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Pro from "./Components/Pro";
import Delight from "./Components/Delight";
import About from "./Components/About";
import Frm from "./Components/Frm";
import Reg from "./Components/Reg";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Pro1 from "./Components/Pro1";
import Abo from "./Components/Abo";
import Product1 from "./Components/Product1";
import { FormFloating } from "react-bootstrap";
import Safty from "./Components/Safty";
import Dow from "./Components/Dow";
import As from "./Components/As";
import G1 from "./Components/G1";
import Login from "./Components/Login";
// import About1 from "./Components/Admin/About1";

import ProSidebar from "./Components/Admin/ProSidebar";
import { ProSidebarProvider } from "react-pro-sidebar";
import Admin from "./Components/Admin/Admin";
import { GiCook } from "react-icons/gi";
// import Bold from "./Components/Admin/Bold";
import News1 from "./Components/Admin/News1";
import Blogs1 from "./Components/Admin/Blogs1";
import Gallery1 from "./Components/Admin/Gallery1";
import Slider from "./Components/Admin/Slider";
import Image1 from "./Components/Admin/Image1";
import MajorProducts from "./Components/Admin/MajorProducts";
// import BetterSecurity from "./Components/Admin/BetterSecurity";
// import Wcs from "./Components/Admin/Wcs";
// import Testimonial1 from "./Components/Admin/Testimonial1";



function App() {
  return (
    <div className="App">
      {/* <Reg /> */}
      {/* <Frm />  */}
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Uptl />
              </>
            }
          />
          <Route
            path="/pro"
            element={
              <>
                <Pro />
              </>
            }
          />
          <Route
            path="/delight"
            element={
              <>
                <Delight />
              </>
            }
          ></Route>
          <Route
            path="/about"
            element={
              <>
                <Abo />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            path="/frm"
            element={
              <>
                <Frm />
              </>
            }
          />
          <Route
            path="/reg"
            element={
              <>
                <Reg />
              </>
            }
          />
          <Route
            path="/pro1"
            element={
              <>
                <Pro1 />
              </>
            }
          />
          <Route
            path="/as"
            element={
              <>
                <As />
              </>
            }
          />
          <Route
            path="/g1"
            element={
              <>
                <G1 />
              </>
            }
          />
          <Route
            path="/safty"
            element={
              <>
                <Safty />
              </>
            }
          />
          <Route
            path="/product1"
            element={
              <>
                <Product1 />
              </>
            }
          />
          <Route
            path="/dow"
            element={
              <>
                <Dow />
              </>
            }
          />

          {/* admin panel */}
          {/* <Route
            path="/bold"
            element={
              <>
                <Admin children={<Bold />} />
              </>
            }
          /> */}
          {/* <Route
            path="/aboute"
            element={
              <>
                <Admin children={<About1 />} />
              </>
            }
          /> */}

          <Route
            path="/slider1"
            element={
              <>
                <Admin children={<Slider />} />
              </>
            }
          />
          <Route
            path="/image1"
            element={
              <>
                <Admin children={<Image1 />} />
              </>
            }
          />
          {/* <Route
            path="/majorproducts1"
            element={
              <>
                <Admin children={<MajorProducts />} />
              </>
            }
          /> */}
          {/* <Route
            path="/bettersecurity1"
            element={
              <>
                <Admin children={<BetterSecurity />} />
              </>
            }
          /> */}
          {/* <Route
            path="/wcs1"
            element={
              <>
                <Admin children={<Wcs />} />
              </>
            }
          /> */}
          {/* <Route
            path="/testimonial1"
            element={
              <>
                <Admin children={<Testimonial1 />} />
              </>
            }
          /> */}
          <Route
            path="/news1"
            element={
              <>
                <Admin children={<News1 />} />
              </>
            }
          />
          <Route
            path="/blogs1"
            element={
              <>
                <Admin children={<Blogs1 />} />
              </>
            }
          />
          <Route
            path="/gallery1"
            element={
              <>
                <Admin children={<Gallery1 />} />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
<ProSidebar />;
export default App;
