import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import N1 from "./N1";

function Reg() {
  return (
    <div>
      <N1 />
      <div
        className="container"
        style={{
          backgroundImage: "url(./photos/feather-340.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "50px",
        }}
      >
        <div
          className="log"
          style={{
            width: "500px",
            padding: "50px 0px",
            margin: "50px 0px 50px 300px",
          }}
        >
          <div class="container">
            {/* <button class="goback" style={{marginLeft:"40rem", "background: linear-gradient(to right, #ff105f, #ffad06)",
        border: "none",
        borderRadius: "5px",
        padding: "5px 10px"
        ,}} onclick="history.back()">&laquo; Go Back</button> */}
            <div class="register" style={{ textAlign: "center" }}>
              <h1 style={{ color: "#0045ff" }}>
                <b>Rigestration</b>
              </h1>
              <div
                class="jjjjj"
                style={{
                  borderBottom: " 5px solid #34f2c9",
                  margin: "0px 426px",
                }}
              ></div>
            </div>
          </div>
          <br />
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {/* <Form.Label>First Name</Form.Label> */}
              <Form.Control type="User Id" placeholder="Enter FirstName" />
              <br />
              <Form.Control type="User Id" placeholder="Enter LastName" />
              <br />
              <Form.Control type="Email" placeholder="Enter Email" />
              <br />
              <Form.Control type="Number" placeholder="MobileNumber" />
              <br />
              <Form.Control type="User Id" placeholder="Enter UserName" />{" "}
              <br />
              <Form.Control type="password" placeholder="Password" />
              <br />
              <Form.Control type="password" placeholder="Confirm Password" />
              <br />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              {/* <Form.Label>Password</Form.Label> */}
              {/* <Form.Control type="password" placeholder="Password" />
              <br />
              <Form.Control type="cpassword" placeholder="Confirm Password" />
              <br /> */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="I agree with the terms and conditions"
                style={{ paddind: "0px -110px" }}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Reg;
