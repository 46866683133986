import React from "react";
// import Button from "react-bootstrap/Button";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import N1 from "./N1";

function Pro() {
  return (
    <div>
      <N1 />
      {/* nav */}

      {/* nav */}
      <div class="product">
        <div
          class="con  "
          style={{
            backgroundImage: "url(./image/safety.webp)",
            height: "400px",
            width: "100%",
            backgroundSize: "cover",
          }}
        >
          <h1 class="fsd" style={{ textAlign: "center", color: "white" }}>
            <b
              class="ggc"
              style={{
                position: "absolute",
                margin: "155px -321px",
              }}
            >
              Saftey & security
            </b>
          </h1>
        </div>

        {/* intro */}
        {/* <div class="ffd">
          <h2 style={{ textAlign: "center" }}>
            <b>
              SAFTEY & SECURITY{" "}
              <span style={{ color: "red" }} >PANELS & SENSORS</span> – SPARTAN
            </b>
          </h2>
          <div
            class="pp"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 466px",
            }}></div>
          <br />
          <br />
          <div class="container">
            <h5>
              <b>
                <li>
                  –SPARTAN Series is UPTL ‘S Premium Category Hybrid[Wired &
                  Wireless] intrusion & Home Automation Control Panel.
                </li>
                <li>
                  SPARTAN is a Professional Modern Wireless Security, Safety
                  Control Panel with Optional Home Automation Capabilities. It
                  is Suitable for Residential, Industrial, Banking Customers and
                  Home Automation System in a single Platform with Integrated
                  4G, IoT, Wireless Dual Mode Wi-Fi Hotspot.
                </li>
                <li>
                  Android App to control & Configure. Customized Web-app for
                  Banking & industries.{" "}
                </li>
                <li>
                  In-built Hotspot to Connect and Provide internet to all Wi-Fi
                  Appliances like TV, Laptop, Mobile Phone etc.
                </li>
                <li>In-built IoT & AI with free Cloud Connectivity.</li>
                <li>CMS Compatible with SIA DC-9/Customizable Protocl.</li>
              </b>{" "}
            </h5>
          </div>
        </div> */}
        <br />
        {/* intro */}

        <br />
        <div class="ffd">
          <h2 style={{ textAlign: "center" }}>
            <b>
              Introduction <span style={{ color: "red" }}>Alarm & safety</span>{" "}
              panel
            </b>
          </h2>
          <div
            class="pp"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 466px",
            }}
          ></div>
          <br />
          <br />
          <div class="container">
            <h5>
              <b>
                The Alarm and Security Systems Policy governs the design,
                implementation, control, and responsibilities for the systems
                and technology related to Alarm Systems and Video Security
                Systems for the University.
              </b>{" "}
            </h5>
          </div>
        </div>
        <br />
        <div class="container">
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>SPARTAN</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 442px",
            }}
          ></div>
          <div class="row">
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/SPARTAN.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">SPARTAN-MAIN-PCB</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
                SPARTAN-MAIN-PCB
              </div>
            </div>
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem", height: "509px" }}>
                <img
                  src="./PRODUCTS/SPARTAN-GSM DIALER.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">SPARTAN-GSM DIALER</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>SPARTAN ICON</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 442px",
            }}
          ></div>
          <div class="row">
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/SPARTN ICON _INSIDE.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">SPARTN ICON _INSIDE</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/SPARTAN-ICON.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">SPARTAN-ICON</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>SPARTAN ELITE</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 442px",
            }}
          ></div>
          <br></br>
          <div class="row">
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/Elite-Box.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">Elite-Box</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/SPARTAN-ELITE-INSIDE.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">SPARTAN-ELITE-INSIDE</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>SPARTAN MICRO</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007af f",
              margin: "0px 442px",
            }}
          ></div>
          <br></br>
          <div class="row">
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/10hr BACKUP BAT.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">Internet enabled</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/BLE-Module.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">2G Dialer</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>SPARTAN AIR</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 442px",
            }}
          ></div>
          <br></br>
          <div class="row">
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/BLUETOOTH MODEL.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">Internet enabled</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/Delight-Controll.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">2G Dialer</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>GLADIATOR</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 442px",
            }}
          ></div>
          <br></br>
          <div class="row">
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/Elite-Box.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">Internet enabled</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card" style={{ width: "30rem" }}>
                <img
                  src="./PRODUCTS/EV-AC_GATEWAY.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h2 class="card-title">2G Dialer</h2>
                  <h6 class="card-subtitle mb-2 text-muted">Wired / Hybrid</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* pro2 */}

        <div class="clr">
          <div class="fr" style={{ textAlign: "center" }}>
            <h1>
              <b>
                DIY <span style={{ color: "red" }}>Spartan</span> Panaels
              </b>
            </h1>
          </div>
          <div
            class="yt"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 525px",
            }}
          ></div>
          <br />
          <h5>
            <b>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae,
              dolore quibusdam facere facilis iusto pariatur accusamus ipsam
              dignissimos consequatur dicta unde veritatis repudiandae sunt
              suscipit rerum laborum assumenda et a.
            </b>
          </h5>

          <br />
          <br />
          <br />
          <div class="ssf" style={{ textAlign: "center" }}>
            <h2>
              <b>Dialer</b>
            </h2>
            <div
              class="ew"
              style={{
                borderBottom: "5px solid #007aff",
                margin: "0px 525px",
              }}
            ></div>
            <br />
            <br />
            <div class="row">
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/HAWK.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>SPARTAN-DIAL</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/EXPANDER-UTPL.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda- GSM GLCD</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/IOT GATEWAY-.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda -GLCD</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/LORA-ADAPTOR.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda-TINT</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <div class="ssf" style={{ textAlign: "center" }}>
            <h2>
              <b>Garuda</b>
            </h2>
            <div
              class="ew"
              style={{
                borderBottom: "5px solid #007aff",
                margin: "0px 525px",
              }}
            ></div>
            <br />
            <br />
            <div class="row">
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/HAWK.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/EXPANDER-UTPL.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda- GSM GLCD</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/IOT GATEWAY-.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda -GLCD</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/LORA-ADAPTOR.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda-TINT</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <div class="fr" style={{ textAlign: "center" }}>
            <h1>
              <b>kit</b>
            </h1>
          </div>
          <div
            class="yt"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 525px",
            }}
          ></div>
          <br />
          <h5>
            <b>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae,
              dolore quibusdam facere facilis iusto pariatur accusamus ipsam
              dignissimos consequatur dicta unde veritatis repudiandae sunt
              suscipit rerum laborum assumenda et a.
            </b>
          </h5>
          <br />
          <br />
          <br />
          <div class="ssf" style={{ textAlign: "center" }}>
            <h2>
              <b>SPARTAN-KIT</b>
            </h2>
            <div
              class="ew"
              style={{
                borderBottom: "5px solid #007aff",
                margin: "0px 525px",
              }}
            ></div>
            <br />
            <br />
            <div class="row">
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/HMI_TOUCH SBC.jpg"
                    class="card-img-top"
                    t="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda - Home 2BHK</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/WIFI+WIFI ADAPTOR.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda - Home 3BHK</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/EV AC-Telimetry.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Spartan Wireless - 2BHK</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/BLUETOOTH MODEL.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Spartan Wireless - 3BHK</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ssf" style={{ textAlign: "center" }}>
            <h2>
              <b>RKP</b>
            </h2>
            <div
              class="ew"
              style={{
                borderBottom: "5px solid #007aff",
                margin: "0px 525px",
              }}
            ></div>
            <br />
            <br />
            <div class="row">
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/Keyfob.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>GLDIATOR-RKP</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/EXPANDER-UTPL.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Wireless RKP</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/HAWK-4G DOngle-2.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Wired RKP </b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/LORA-ADAPTOR.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Wired RKP</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <div class="ssf" style={{ textAlign: "center" }}>
            <h2>
              <b>Intrusion Sensors</b>
            </h2>
            <div
              class="ew"
              style={{
                borderBottom: "5px solid #007aff",
                margin: "0px 525px",
              }}
            ></div>
            <br />
            <br />
            <div class="row">
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/HAWK.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/EXPANDER-UTPL.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda- GSM GLCD</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/IOT GATEWAY-.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda -GLCD</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ width: "16rem" }}>
                  <img
                    src="./PRODUCTS/LORA-ADAPTOR.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <h5 class="card-title">
                    <b>Garuda-TINT</b>
                  </h5>
                  <div class="card-body">
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
        {/* pro2 */}
      </div>
    </div>
  );
}

export default Pro;
