import React from "react";
import OwlCarousel from "react-owl-carousel";
//
import Card from "react-bootstrap/Card";
import { GiVillage } from "react-icons/gi";
import { AiFillShopping } from "react-icons/ai";
import { MdCorporateFare } from "react-icons/md";
import { TbSquareToggleHorizontal } from "react-icons/tb";
import { GiSpookyHouse } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";
import { FaHotel } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { MdSchool } from "react-icons/md";
import { FaCarAlt } from "react-icons/fa";
import { GiRollingEnergy } from "react-icons/gi";
import { MdFiberManualRecord } from "react-icons/md";
//
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Alert } from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Utpl.css";
import "../App.css";
import Carousel from "react-bootstrap/Carousel";
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import N1 from "./N1";

// {/* <script>
//   <script
//     src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js"
//     integrity="sha384-OERcA2EqjJCMA+/3y+gxIOqMEjwtxJY7qPCqsdltbNJuaOe923+mo//f6V8Qbsw3"
//     crossorigin="anonymous"></script>
//   <script
//     type="text/javascript"
//     src="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/6.0.0/mdb.min.js"></script>
//   {/*links  */}
//   <link
//     href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
//     rel="stylesheet"
//   />
//   <link
//     href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
//     rel="stylesheet"
//   />
//   <link
//     href="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/6.0.0/mdb.min.css"
//     rel="stylesheet"
//   />

//   <title>UPTL</title>
//   <link
//     href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
//     rel="stylesheet"
//     integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"
//     crossorigin="anonymous"
//   />
//   <link
//     rel="stylesheet"
//     href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
//   />
//   <script
//     src="https://kit.fontawesome.com/a076d05399.js"
//     crossorigin="anonymous"></script>
//   {/* reactbootstrap */}
//   <script
//     src="https://cdn.jsdelivr.net/npm/react/umd/react.production.min.js"
//     crossorigin></script>

//   <script
//     src="https://cdn.jsdelivr.net/npm/react-dom/umd/react-dom.production.min.js"
//     crossorigin></script>

//   <script
//     src="https://cdn.jsdelivr.net/npm/react-bootstrap@next/dist/react-bootstrap.min.js"
//     crossorigin></script>

//   <script>var Alert = ReactBootstrap.Alert;</script>
//   <link
//     rel="stylesheet"
//     href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
//     integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"
//     crossorigin="anonymous"
//   />
// </script>; */}

function Uptl() {
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  };
  return (
    <>
      <div>
        <N1 />
        {/* nav */}
        {/* <div class="khali" style={{ fontSize: "18px" }}>
          <nav
            class="navbar navbar-expand-lg bg-light"
            style={{ width: "100%" }}>
            <div class="container-fluid">
              <div class="img">
                <img
                  src="/LOGO/LOGO/final logo png.png"
                  style={{ height: "43px", width: "95px" }}
                />
              </div>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="/"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>Home</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle active"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ padding: "0px 12px" }}>
                      <b>Products</b>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="/pro">
                          product &raquo;
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/pro1">
                        IOT ENABLED &raquo;
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/delight">
                          delight &raquo;
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>Solutions</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>UTPL- USP</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>Support</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="/about"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>About us </b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>Partner with us</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>DOWNLOADS</b>
                    </a>
                  </li>
                </ul>
              </div>
              <a
                type="button"
                class="btn1"
                href="./reg"
                style={{
                  background: "linear-gradient(to right, #ff105f, #ffad06)",
                  color: "white",
                  padding: "0px 5px",
                  borderRadius: "30px",
                }}>
                Login/Register
              </a>
            </div>
          </nav>
        </div> */}
        {/* nav */}
        {/* slider */}
        <Carousel>
          <Carousel.Item style={{ backgroundColor: "" }}>
            <img
              className="d-block w-100"
              src="/image/spartan.jpg"
              alt="First slide"
              style={{ height: "500px" }}
            />
            <Carousel.Caption>
              <h3
                className="name"
                style={{
                  marginTop: "-270px",
                  fontSize: "70px",
                  backgroundColor: "rgba(54, 57, 62, 0.5)",
                }}
              >
                Spartan security
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/ai-nuclear-energy-background-future-innovation-disruptive-technology_53876-129783.webp"
              alt="Second slide"
              style={{ height: "500px" }}
            />

            <Carousel.Caption>
              <h3
                className="name"
                style={{
                  marginTop: "-270px",
                  fontSize: "70px",
                  backgroundColor: "rgba(54, 57, 62, 0.5)",
                }}
              >
                Automation
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/image/fire.webp"
              alt="Third slide"
              style={{ height: "500px" }}
            />

            <Carousel.Caption>
              <h3
                className="name"
                style={{
                  marginTop: "-270px",
                  fontSize: "70px",
                  backgroundColor: "rgba(54, 57, 62, 0.5)",
                }}
              >
                Fire And Saftey
              </h3>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/image/connectivity.jpg"
              alt="First slide"
              style={{ height: "500px" }}
            />
            <Carousel.Caption>
              <h3
                className="name"
                style={{
                  marginTop: "-270px",
                  fontSize: "70px",
                  backgroundColor: "rgba(54, 57, 62, 0.5)",
                }}
              >
                Connectivity
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/r1.webp"
              alt="Second slide"
              style={{ height: "500px" }}
            />

            <Carousel.Caption>
              <h3
                className="name"
                style={{
                  marginTop: "-270px",
                  fontSize: "70px",
                  backgroundColor: "rgba(54, 57, 62, 0.5)",
                }}
              >
                Audio Video
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/r.webp"
              alt="Third slide"
              style={{ height: "500px" }}
            />

            <Carousel.Caption>
              <h3
                className="name"
                style={{
                  marginTop: "-270px",
                  fontSize: "70px",
                  backgroundColor: "rgba(54, 57, 62, 0.5)",
                }}
              >
                CMS RUDRA
              </h3>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/photos/istock.jpg"
              alt="Third slide"
              style={{ height: "500px" }}
            />

            <Carousel.Caption>
              <h3
                className="name"
                style={{
                  marginTop: "-270px",
                  fontSize: "70px",
                  backgroundColor: "rgba(54, 57, 62, 0.5)",
                }}
              >
                Of The Shelf Products
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <br></br>
        <div className="container">
          <div className="ra">
            <img
              class="re"
              src="./images/W.jpeg"
              style={{ height: "500px", width: "100%", borderRadius: "42px" }}
            />
          </div>
          <br></br>
        </div>
        <div class="container">
          <div class="majorp" style={{ textAlign: "center" }}>
            <h1 style={{ color: "#0045ff" }}>
              <b>Major Products</b>
            </h1>
            <div
              class="jjjjj"
              style={{
                borderBottom: " 5px solid #34f2c9",
                margin: "0px 390px",
              }}
            ></div>
            <br />
            <p style={{ fontSize: "25px;" }}>Matrix of major products</p>
            <br />
          </div>
        </div>
        <div class="container">
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            items={6}
            autoplay={true}
            nav
            responsive={responsive}
            dots={false}
          >
            <div class="item">
              <h4>SPARTAN</h4>
            </div>
            <div class="item">
              <h4>SPARTAN ICON</h4>
            </div>
            <div class="item">
              <h4>SPARTAN ELITE</h4>
            </div>
            <div class="item">
              <h4>SPARTAN MICRO</h4>
            </div>
            <div class="item">
              <h4>SPARTAN AIR</h4>
            </div>
            <div class="item">
              <h4>GLADIATOR</h4>
            </div>
            <div class="item">
              <h4>Garuda</h4>
            </div>
            <div class="item">
              <h4>SPARTAN-DIAL</h4>
            </div>
            <div class="item">
              <h4>SPARTAN-KIT</h4>
            </div>
            <div class="item">
              <h4>GLDIATOR-RKP</h4>
            </div>
            <div class="item">
              <h4>Wireless RKP</h4>
            </div>
            <div class="item">
              <h4>Wired RKP</h4>
            </div>
          </OwlCarousel>

          <div class="row">
            <div class="col-md-3">
              <div class="card" style={{ width: "14rem" }}>
                <img src="./PRODUCTS/HAWK.jpg" class="card-img-top" alt="..." />
                <h5 class="card-title">
                  <b>SPARTAN-DIAL</b>
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" style={{ width: "14rem" }}>
                <img
                  src="./PRODUCTS/EXPANDER-UTPL.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <h5 class="card-title">
                  <b>Garuda- GSM GLCD</b>
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" style={{ width: "14rem" }}>
                <img
                  src="./PRODUCTS/IOT GATEWAY-.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <h5 class="card-title">
                  <b>Garuda -GLCD</b>
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" style={{ width: "14rem" }}>
                <img
                  src="./PRODUCTS/LORA-ADAPTOR.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <h5 class="card-title">
                  <b>Garuda-TINT</b>
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* snap shot */}
        {/* inst */}
        {/* <div class="container">
          <div class="row">
            <div class="col-md-3">
              <div class="card" style={{ width: "18rem" }}>
                <div class="card-body">
                  <h3 class="card-title" style={{ height: "95px" }}>
                    <b>Highly garunteed installer </b>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" style={{ width: "18rem" }}>
                <img src="/image/cctv.jpg" alt="..." />
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" style={{ width: "18rem" }}>
                <img src="/image/security.jpg" alt="..." />
              </div>
            </div>
            <div class="col-md-3">
              <div class="card" style={{ width: "18rem" }}>
                <img src="/image/alarm system.jpg" alt="..." />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br /> */}
        <div class="container">
          <div class="row">
            <div class="col-md-6" style={{ margin: "76px 0px" }}>
              <h1 style={{ fontSize: "32px" }}>
                <b>
                  When you need better
                  <span style={{ color: "red " }}>security</span> installation
                </b>
              </h1>
              <br />
              <br />
              <p1 style={{ fontSize: "20px" }}>
                We have the skilled and qualified staffs to install the security
                system protects your place and introduced. we expect to continue
                our relationship long after your new equipment is installed.
              </p1>
              <br />
              <br />
              <button
                type="button"
                class="btn btn-danger"
                style={{ margin: "-3px 64px", width: "30%" }}
              >
                Get in Touch
              </button>
            </div>
            <div class="col-md-6">
              <img
                class="man"
                src="/image/one-man.png"
                style={{
                  marginLeft: "176px",
                  verticalAlign: "middle",
                  height: "350px",
                  marginTop: "100px",
                  width: "auto",
                }}
              />
            </div>
          </div>
        </div>
        <div class="gfg">
          <br />
          <br />
          <div
            class="lkj"
            style={{
              backgroundColor: "",
              margin: "-41px 0px",
              height: "494px",
            }}
          >
            {/* inst */}
            <div class="lkj">
              <div class="container">
                <h1
                  class="hh"
                  style={{
                    textAlign: "center",
                    color: "blue",
                    fontSize: "50px",
                  }}
                >
                  <div class="why" style={{ textAlign: "center" }}>
                    <h1 style={{ color: "#0045ff" }}>
                      <b>Why choose us?</b>
                    </h1>
                    <div
                      class="jjjjj"
                      style={{
                        borderBottom: "5px solid #34f2c9",
                        margin: "0px 426px",
                      }}
                    ></div>
                  </div>
                  <br />
                </h1>

                <div class="row">
                  <div class="col-md-3">
                    <div
                      class="card"
                      style={{
                        width: "17rem",
                        height: "300px",
                        margin: "-20px 0px",
                        backgroundColor: "rgba(5, 29, 62, 0.842)",
                        color: "white",
                      }}
                    >
                      <div class="card-body">
                        <h2 class="card-title">
                          <b style={{ fontSize: "27px" }}>Choose us as ..</b>
                        </h2>
                        <p class="card-text">
                          We provide technologically advanced, premium quality
                          yet affordable products catering to the needs of
                          various commercial and domestic segments.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div
                      class="card"
                      style={{
                        width: "17rem",
                        height: "300px",
                        margin: "-20px 0px",
                        backgroundColor: "rgba(5, 29, 62, 0.842)",
                        color: "white",
                      }}
                    >
                      <div class="card-body">
                        <i class="fa-solid fa-shield-halved"></i>
                        <h2 class="card-title">
                          <b style={{ fontSize: "27px" }}>Quality</b>
                        </h2>
                        <p class="card-text">
                          Our quality and reliability meets global standards
                          with regards to material quality and manufacturing
                          facility. Our quality management includes testing
                          under toughest conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div
                      class="card"
                      style={{
                        width: "17rem",
                        height: "300px",
                        margin: "-20px 0px",
                        backgroundColor: "rgba(5, 29, 62, 0.842)",
                        color: "white",
                      }}
                    >
                      <div class="card-body">
                        <h2 class="card-title">
                          <b style={{ fontSize: "27px" }}>Guaranteed</b>
                        </h2>
                        <p class="card-text">
                          Solutions for every specific security, Connectivity
                          and automation requirements designed with precision
                          and innovation.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div
                      class="card"
                      style={{
                        width: "17rem",
                        height: "300px",
                        margin: "-20px 0px",
                        backgroundColor: "rgba(5, 29, 62, 0.842)",
                        color: "white",
                      }}
                    >
                      <div class="card-body">
                        <i class="fa-solid fa-shield-halved"></i>
                        <h2 class="card-title">
                          <b style={{ fontSize: "27px" }}>Support </b>
                        </h2>
                        <p class="card-text">
                          Our technical support/ after sales support is an
                          expert driven team available whenever/whereever
                          needed. We are just a call or and E-mail away to
                          provide instant solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* testimonial */}
        <div class="container">
          <div class="hhh" style={{ textAlign: "center" }}>
            <h1 className="tet" style={{ color: "#0045ff" }}>
              Testimonial
            </h1>
            <div
              class="jjjjj"
              style={{
                borderBottom: " 5px solid #34f2c9",
                margin: "0px 426px",
              }}
            ></div>
          </div>
        </div>
        <br />
        <div
          class="cn"
          style={{
            marginLeft: "150px",
            marginRight: "150px",
            fontSize: "20px",
            color: "red",
          }}
        >
          <i class="fa fa-quote-left"></i>
          <h4>
            {" "}
            "Great service today! Everything is working great and appreciate our
            s ecurity taking the number of best experience conservative route
            installment.
          </h4>
          <br />
          <div class="testimonial-name" style={{ marginLeft: "42px" }}>
            <img
              src="./photos/testimonial.jpg"
              alt=""
              style={{ borderRadius: "50%" }}
            />
          </div>
          <span style={{ marginLeft: "42px", color: "black" }}>
            Maria Hedge
          </span>
        </div>
        {/* corsaral */}
        {/* team */}
        {/* team */}
        {/* segment */}
        <div class="container">
          <div class="hh" style={{ textAlign: "center", marginTop: "50px" }}>
            <h1 style={{ color: "#0045ff" }}>
              <b>Segments</b>
            </h1>
            <div
              class="jjjjj"
              style={{
                borderBottom: "5px solid #34f2c9",
                margin: "0px 426px",
              }}
            ></div>
            <br />
          </div>
        </div>
        <div class="container">
          <div className="row">
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <GiVillage style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b> RESIDENTIAL </b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <AiFillShopping style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b>SHOPE</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <MdCorporateFare style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b>CORPORATE</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <TbSquareToggleHorizontal style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b>RETAIL</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <GiSpookyHouse style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b> ESTABLISH MENTS </b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <TbTruckDelivery style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b>Logistics</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <FaHotel style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b>Hotel & Leisure</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <BsBank2 style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b>BANK,ATM</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <br></br>

          <div className="row">
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <MdSchool style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b1> INSTITUTIONS </b1>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <FaCarAlt style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b> VEHICLES</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <GiRollingEnergy style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b>Energy</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Body>
                  <MdFiberManualRecord style={{ fontSize: "40px" }} />
                  <Card.Title>
                    <b>Manufacturing</b>
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>{" "}
        <br />
        <br />
        {/* segment */}
        {/* blogs */}
        <div class="container">
          <div class="h" style={{ textAlign: "center" }}>
            <h1 style={{ color: "#0045ff" }}>
              <b>Blogs</b>
            </h1>
            <div
              class="jjjjj"
              style={{
                borderBottom: "5px solid #34f2c9",
                margin: "0px 426px",
              }}
            ></div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="card" style={{ width: "22rem" }}>
                <img
                  src="/images/AdobeStock_122207076-scaled.jpeg"
                  class="card-img-top"
                  alt="..."
                />
                <div
                  class="card-body"
                  style={{ backgroundColor: "#e5f0f5", height: "287px" }}
                >
                  <h5
                    class="card-title-dd"
                    style={{ color: "initial", color: "#0045ff" }}
                  >
                    <b>
                      iLink Digital Partners with Databricks to Drive Business
                      Value with the Lakehouse Platform
                    </b>
                  </h5>
                  <p class="card-text-kkv">
                    Seattle, WA, November 10th, 2022 – iLink Digital announced
                    that they have partnered with Databricks, the lakehouse
                    company. This partnership will provide data.
                  </p>
                  <a href="#" style={{ color: "#0045ff" }}>
                    Read more &raquo;
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card" style={{ width: "22rem" }}>
                <img
                  src="https://www.ilink-digital.com/wp-content/uploads/2022/08/two-content-business-partners-discussing-issue-min-1-scaled-480x300.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div
                  class="card-body"
                  style={{ backgroundColor: "#e5f0f5", height: "300px" }}
                >
                  <h5
                    class="card-title-cd"
                    style={{ color: "initial", color: "#0045ff" }}
                  >
                    <b>
                      Centralized Data Storage System: How your Business can
                      benefit?
                    </b>
                  </h5>
                  <p class="card-text">
                    Data is the backbone of a business. It helps companies
                    predict trends, identify opportunities, get customer
                    insights, and stay ahead of the competition. Hence they
                  </p>
                  <a href="#" style={{ color: "#0045ff" }}>
                    Read more &raquo;
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card" style={{ width: "22rem" }}>
                <img
                  src="/images/building-supercomputer-in-database-center-EWP7QMV-1-min-1024x683.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div
                  class="card-body"
                  style={{ backgroundColor: "#e5f0f5", height: "287px" }}
                >
                  <h5 class="card-title-kb" style={{ color: "#0045ff" }}>
                    <b>
                      How OCR Technology is Optimizing Processes Across
                      Industries?
                    </b>
                  </h5>
                  <p class="card-text">
                    Optical Character Recognition (OCR) has been one of the
                    earliest technological evolutions that continues to gain
                    prominence with time. It helps businesses automate the
                    processing
                  </p>
                  <a href="#" style={{ color: "#0045ff" }}>
                    Read more &raquo;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /> <br />
        {/* blogs */}
        {/* news */}
        <div class="container">
          <div class="n" style={{ textAlign: "center" }}>
            <h1 style={{ color: "#0045ff" }}>
              <b>News</b>
            </h1>
            <div
              class="jjjjj"
              style={{
                borderBottom: "5px solid #34f2c9",
                margin: "0px 426px",
              }}
            ></div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="card">
              <h2>
                Tame Active Directory groups to streamline management, prep for
                automation
              </h2>
              <h5>
                Best practices for managing identities and permissions in Active
                Directory can help enterprises handle complexity as AD
                environments scale.
              </h5>
              <div
                class="fakeimg"
                style={{
                  height: "200px",
                  background: "url(./photos/n1.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
              <p>Some text..</p>
              <p>
                Over the years, Microsoft and others have developed best
                practices for managing groups and permissions in an Active
                Directory environment. These strategies are something of a lost
                art, but there’s value to be gained by leveraging these layers
                of sophistication.
              </p>
            </div>
          </div>{" "}
          <br />
          {/* news */}
          {/* gallery */}
          <div class="container">
            <div class="gry" style={{ textAlign: "center" }}>
              <p style={{ color: "#7a9bf5" }}></p>
              <h1 style={{ color: "#0045ff" }}>
                <b>Gallery</b>
              </h1>
              <div
                class="jjjjj"
                style={{
                  borderBottom: "5px solid #34f2c9",
                  margin: "0px 426px",
                }}
              ></div>
            </div>

            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <div class="card" style={{ width: "22rem" }}>
                    <div class="card-body">
                      <img
                        className="office"
                        src="./OFFICE/Devp.jpg"
                        alt=""
                        style={{
                          height: "270px",
                          width: "21rem",
                          marginLeft: "-29px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="card" style={{ width: "22rem" }}>
                    <div class="card-body">
                      <img
                        className="office"
                        src="./OFFICE/LAB.jpg"
                        alt=""
                        style={{
                          height: "270px",
                          width: "21rem",
                          marginLeft: "-29px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="card" style={{ width: "22rem" }}>
                    <div class="card-body">
                      <img
                        className="office"
                        src="./OFFICE/Mangement.jpg"
                        alt=""
                        style={{
                          height: "270px",
                          width: "21rem",
                          marginLeft: "-29px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="card" style={{ width: "22rem" }}>
                    <div class="card-body">
                      <img
                        className="office"
                        src="./OFFICE/Office-Staff.jpg"
                        alt=""
                        style={{
                          height: "270px",
                          width: "21rem",
                          marginLeft: "-29px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="card" style={{ width: "22rem" }}>
                    <div class="card-body">
                      <img
                        className="office"
                        src="./OFFICE/R&D.jpg"
                        alt=""
                        style={{
                          height: "270px",
                          width: "21rem",
                          marginLeft: "-29px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="card" style={{ width: "22rem" }}>
                    <div class="card-body">
                      <img
                        className="office"
                        src="./OFFICE/R&D2.jpg"
                        alt=""
                        style={{
                          height: "270px",
                          width: "21rem",
                          marginLeft: "-29px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/* gallery */}
        <footer class="bg-primary text-white text-center text-lg-start">
          <div class="container p-4">
            <div class="row">
              <h3>UPTL TECHNOLOGY PVT.LTD.</h3>
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="text-uppercase">Corporate office:</h5>

                <ul class="list-unstyled mb-0">
                  <p>
                    2nd floor L.K.S complex, BBMP NO.309, 10th main,1st block,
                    Kalyan nagar, HRBR layout Benguluru-560043
                  </p>
                </ul>
                <div
                  class="icons d-flex"
                  style={{
                    marginTop: "20px",
                    fontSize: "50px",
                    marginLeft: "157px",
                  }}
                >
                  <FaFacebookSquare />
                  <FaInstagram />
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="text-uppercase ">Registered office:</h5>

                <ul class="list-unstyled">
                  <p>
                    L-144, Gulshan Vivnate sector-137, Noida, Uttar
                    Pradesh-201304
                  </p>
                </ul>
                <div
                  class="icons d-flex"
                  style={{
                    marginTop: "68px",
                    fontSize: "50px",
                    marginLeft: "-20px",
                  }}
                >
                  <FaTwitter />
                  <FaYoutube />
                </div>
              </div>
              {/* --- */}

              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="text-uppercase">Links</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <a href="#!" class="text-dark">
                      Link 1
                    </a>
                  </li>
                  <li>
                    <a href="#!" class="text-dark">
                      Link 2
                    </a>
                  </li>
                  <li>
                    <a href="#!" class="text-dark">
                      Link 3
                    </a>
                  </li>
                  <li>
                    <a href="#!" class="text-dark">
                      Link 4
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="text-uppercase mb-0">Links</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <a href="#!" class="text-dark">
                      Link 1
                    </a>
                  </li>
                  <li>
                    <a href="#!" class="text-dark">
                      Link 2
                    </a>
                  </li>
                  <li>
                    <a href="#!" class="text-dark">
                      Link 3
                    </a>
                  </li>
                  <li>
                    <a href="#!" class="text-dark">
                      Link 4
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            class="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            UPTL TECHNOLOGY PVT.LTD.
            <a class="text-dark" href="https://mdbootstrap.com/"></a>
          </div>
        </footer>
        {/* footer */}
      </div>
    </>
  );
}

export default Uptl;
