import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../App.css";

function N1() {
  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">
            {" "}
            <div class="img">
              <img
                src="/LOGO/LOGO/final logo png.png"
                style={{ height: "43px", width: "95px" }}
              />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              // style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="/">Home</Nav.Link>

              {/* <Nav.Link href="#action2">Link</Nav.Link> */}
              <NavDropdown title="Products" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/pro">
                  {" "}
                  PRODUCT &raquo;
                </NavDropdown.Item>
                <NavDropdown.Item href="/safty">
                  SAFTEY & SECURITY &raquo;
                </NavDropdown.Item>
                <NavDropdown.Item href="/as">
                  ALARM SYSTEM &raquo;
                </NavDropdown.Item>
                <NavDropdown.Item href="/pro1">
                  IOT ENABLED &raquo;
                </NavDropdown.Item>
                <NavDropdown.Item href="/product1">
                  SPARTAN ELITE AIR &raquo;
                </NavDropdown.Item>
                <NavDropdown.Item href="/g1">
                  GLADIATOR &raquo;
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/delight">
                  DELIGHT &raquo;
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="#action2">Solutions</Nav.Link>
              <Nav.Link href="#action2">UTPL- USP</Nav.Link>
              <Nav.Link href="#action2">Support</Nav.Link>
              <Nav.Link href="/about">About us</Nav.Link>
              <Nav.Link href="#action2">Partner with us</Nav.Link>
              <Nav.Link href="/dow">Downloads</Nav.Link>
            </Nav>
            <Form className="d-flex">
              {/* <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            /> */}
              <Button
                variant="outline-success"
                href="/login"
                style={{ color: "blue" }}
              >
                Login/Register
              </Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>{" "}
    </div>
  );
}

export default N1;
