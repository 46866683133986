import React from "react";
import Button from "react-bootstrap/Button";
import { MdLogin } from "react-icons/md";
import { RiContactsFill } from "react-icons/ri";
import Carousel from "react-bootstrap/Carousel";
// import "./Login.css";
import N1 from "./N1";

function Login() {
  return (
    <>
      <N1 />
      <div className="row">
        <div className="col-md-6">
          <div className="card" style={{ height: "420px" }}>
            <div
              class="clearfix"
              style={{ color: "#3b5998", fontSize: "24px", marginTop: "70px" }}
            >
              <h4>GateWay To Your Account</h4>
              <p>Manage your account, orders, rewards, and other details. </p>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <a
                  className="lg"
                  href="./frm"
                  style={{ textDecoration: "none", fontSize: "20px" }}
                >
                  <MdLogin />
                  Login
                </a>
              </div>
              <div className="col-md-6">
                <a
                  className="lg"
                  href="./reg"
                  style={{ textDecoration: "none", fontSize: "20px" }}
                >
                  <RiContactsFill />
                  Register
                </a>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="col-md-6">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="./o1.jpg"
                alt="First slide"
                style={{ height: "422px", width: "500px" }}
              />
              <Carousel.Caption>
                <h3>First slide label</h3>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="o2.jpg"
                alt="Second slide"
                style={{ height: "422px", width: "500px" }}
              />

              <Carousel.Caption>
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="o3.jpg"
                alt="Third slide"
                style={{ height: "422px", width: "500px" }}
              />

              <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Login;
