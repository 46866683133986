import React from "react";
import "../App.css";
import N1 from "./N1";

function Delight() {
  return (
    <div>
      <N1 />
      {/* nav */}
      {/* <div class="khali" style={{ fontSize: "18px" }}>
          <nav
            class="navbar navbar-expand-lg bg-light"
            style={{ width: "100%" }}>
            <div class="container-fluid">
              <div class="img">
                <img
                  src="/LOGO/LOGO/final logo png.png"
                  style={{ height: "43px", width: "95px" }}
                />
              </div>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="/"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>Home</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle active"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ padding: "0px 12px" }}>
                      <b>Products</b>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="/pro">
                          product &raquo;
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/delight">
                          delight &raquo;
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>Solutions</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>UTPL- USP</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>Support</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="/about"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>About us </b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>Partner with us</b>
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      href="#"
                      role="button"
                      style={{ padding: "0px 12px" }}>
                      <b>DOWNLOADS</b>
                    </a>
                  </li>
                </ul>
              </div>
              <a
                type="button"
                class="btn1"
                href="./reg"
                style={{
                  background: "linear-gradient(to right, #ff105f, #ffad06)",
                  color: "white",
                  padding: "0px 5px",
                  borderRadius: "30px",
                }}>
                Login/Register
              </a>
            </div>
          </nav>
        </div> */}
      {/* nav */}
      <div class="container">
        <img
          src="photos/musee-des-confluances-1004423__340.jpg"
          class="img-fluid"
          alt="Responsive image"
          style={{ width: "100%", height: "400px" }}
        />
        <div class="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
          <div
            class="d-flex justify-content-center align-items-center h-100"
            style={{ marginLeft: "-100px" }}>
            <h1
              class="text-white mb-0 align-items-center"
              style={{ fontSize: "100px" }}>
              Delight
            </h1>
            <ul
              class="text-white mb-0"
              style={{
                display: "flex",
                margin: "80px 0px 0px -195px",
                fontSize: "20px",
              }}></ul>
          </div>
        </div>
        <br />

        <div class="container">
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>Touch switches</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 442px",
            }}></div>
          <br></br>
          <div class="row">
            <div class="col-md-6">
              <div class="card d" style={{ width: "30rem" }}>
                <img src="./photos/t1.png" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h2 class="card-title">
                    8 Gang (7 Switch + 1 Fan) IR Remote & Wi-Fi Touch Switch
                  </h2>
                  <h6 class="card-subtitle mb-2 text-muted">
                    SKU: IO-7S1F-SPRN-01-BK
                  </h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card d" style={{ width: "30rem" }}>
                <img src="./photos/t2.png" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h2 class="card-title">
                    8 Gang (3 Switch + 1 Fan + 1 Plug) IR Remote & Wi-Fi Touch
                    Switch
                  </h2>
                  <h6 class="card-subtitle mb-2 text-muted">
                    SKU: IO-3S1F1P-SPRN-01-BK
                  </h6>
                  <p class="card-text">.</p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>FAN Controller</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 442px",
            }}></div>
          <br></br>
          <div class="row">
            <div class="col-md-6">
              <div class="card d" style={{ width: "30rem" }}>
                <img src="./photos/f1.jpg" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h2 class="card-title">
                    Anchor by Panasonic Roma Plus Modular Polycarbonate
                  </h2>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </h6>
                  <p class="card-text"></p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card d" style={{ width: "30rem" }}>
                <img src="./photos/f2.webp" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h2 class="card-title">
                    Microchip ADM00879 EMC2305 & EMC1438
                  </h2>
                  <h6 class="card-subtitle mb-2 text-muted">
                    {" "}
                    Fan Ctrl and Temp Demo Fan Controller for EMC1438, EMC2305
                    for EMC1438 Temperature
                  </h6>
                  <p class="card-text"></p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <h1 class="SPARTAN" style={{ textAlign: "center" }}>
            <b>Curtain Controller</b>
          </h1>
          <div
            class="rr"
            style={{
              borderBottom: "5px solid #007aff",
              margin: "0px 442px",
            }}></div>
          <br></br>
          <div class="row">
            <div class="col-md-6">
              <div class="card d" style={{ width: "30rem" }}>
                <img src="./photos/c1.webp" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h2 class="card-title">
                    Somfy Telis 4 RTS Pure Remote, 5 Channel (1810633)
                  </h2>
                  <h6 class="card-subtitle mb-2 text-muted">No Reviews Yet</h6>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card d" style={{ width: "30rem" }}>
                <img src="./photos/c2.jpg" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h2 class="card-title">
                    Okos Smart Curtain/Blind/Tubular/Projector
                  </h2>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Lift Motor Switch Wi-Fi | Compatible with Alexa and Google
                    Home | App Controlled Roma Black Glass Finish
                  </h6>
                  <p class="card-text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Delight;
