import React from "react";
import Card from "react-bootstrap/Card";
import N1 from "./N1";

function Dow() {
  return (
    <>
      <N1 />
      <div className="container">
        <Card className="bg-dark text-white">
          <Card.Img
            src="./images/WhatsApp Image 2022-11-29 at 2.23.10 AM (1).jpeg"
            alt="Card image"
            style={{ width: "100%" }}
          />
        </Card>
      </div>
    </>
  );
}

export default Dow;
