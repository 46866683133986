import React from "react";
import ProSidebar from "./ProSidebar";

function Admin(props) {
  return (
    <>
      <div>
        <div className="row">
          <div
            className="col-md-3"
            style={{
              border: "1px solid green",
              height: "600px",
              backgroundColor: "green",
              color: "white",
            }}
          >
            <div className="container">
              <img
                className="ssk"
                src="./LOGO/LOGO/final logo png.png"
                style={{ width: "20%", height: "50px" }}
              />

              <br></br>
              <br></br>
              <hr></hr>
              <img
                className="xx"
                src="./images/kk.png"
                style={{ height: "80px", width: "35%", borderRadius: "60px" }}
              />
              <h4>
                <b>Ratheesh Vikram Laila</b>
                <br></br>
                <span style={{ color: "green" }}>Co-Founder</span>
              </h4>
              <hr></hr>
              <h6>DASHBOARDS</h6>
              <hr></hr>

              {/* <item></item> */}
              <div
                className="container"
                style={{
                  textAlign: "left",
                  lineHeight: "2.7rem",
                  color: "white",
                }}
              >
                <ul>
                  {/* <a href="/bold">
                    {" "}
                    <li>bold</li>
                  </a> */}
                  {/* 
                  <a href="/aboute">
                    {" "}
                    <li>About</li>
                  </a> */}
                  {/* <a href="">
                {" "}
                <li>Home</li>
              </a> */}
                  <a href="/slider1" style={{ color: "white" }} >
                    {" "}
                    <li>Slider</li>
                  </a>
                  <a href="/image1" style={{ color: "white" }}>
                    {" "}
                    <li>Image</li>
                  </a>
                  {/* <a href="/majorproducts1">
                    {" "}
                    <li>Major Products</li>
                  </a> */}
                  {/* <a href="/bettersecurity1">
                    {" "}
                    <li>Better Security</li>
                  </a> */}
                  <a href="/wcs1" style={{ color: "white" }}>
                    {" "}
                    <li>Why Choose Us</li>
                  </a>
                  {/* <a href="/testimonial1">
                    {" "}
                    <li>Testimonial</li>
                  </a> */}
                  <a href="/news1" style={{ color: "white" }}>
                    {" "}
                    <li>News</li>
                  </a>
                  <a href="blogs1" style={{ color: "white" }}>
                    {" "}
                    <li>Blogs</li>
                  </a>
                  <a href="gallery1" style={{ color: "white" }}>
                    {" "}
                    <li>Gallery</li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-9">{props.children}</div>
        </div>
      </div>
    </>
  );
}

export default Admin;
