import React from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import N1 from "./N1";
import "./About.css";

function As() {
  return (
    <>
      <N1 />
      {/* s */}
      <br />
      <div class="ffd">
        <h2 style={{ textAlign: "center" }}>
          <h2>INTRODUCTION / BURGLAR ALARM SYSTEM</h2>
          <b>
            ALARM <span style={{ color: "red" }}>SYSTEM</span>{" "}
            <div
              class="pp"
              style={{
                borderBottom: "5px solid #007aff",
                margin: "0px 466px",
              }}
            ></div>
          </b>
        </h2>

        <b>With External lead acid Battery</b>
      </div>
      <br />
      <br />
      <div class="container">
        <h5> </h5>
      </div>
      {/*  e*/}
      {/* s1 */}
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              className="spa"
              src="./spartan.jpg"
              style={{ height: "300px", width: "500px" }}
            ></img>
          </div>
          <div className="col-md-6">
            <Card border="success" style={{ width: "30rem" }}>
              <Card.Body>
                <Card.Title>SPARTAN</Card.Title>
                <Card.Text>
                  <ul>
                    <li>Option for 7Ah 12v External Battery</li>
                    <li>NO DIAL, 2G SMS , 4GSMS Options</li>
                    <li>Wired & Hybrid Models</li>
                    <li>Default Add On – Wired Remote Keypad</li>
                    <li>Add On Ack Based Wireless Option</li>
                    <li>Add on – Wireless Keypad</li>
                    <li>Add on –ETH , WIFI</li>
                    {/* <li>In built Battery with</li>
                    <li>4 Hour Battery Back up Option</li>
                    <li>10 Hour Battery Backup Option</li>
                    <li>NO DIAL, 2G SMS , 4GSMS Options</li>
                    <li>Wired , Hybrid Models</li>
                    <li>Default Wired Remote Keypad</li>
                    <li>Add On Ack Based Wireless Option</li>
                    <li>Add on – Wireless Keypad & Wired Keypad</li>
                    <li>Add on- WIFI</li> */}
                  </ul>
                  <h6>
                    Size: 4 & 8 zone Panel : 11” x 7.5” x 3” 12 & 16 Zone Panel
                    “ 12.5”x 8.25” x 3” 24, 30 & 40 Zone Panel “ 12.5”x 15” x 4”
                  </h6>
                  <h6>Wired Zones - 4 , 8, 12, 16, 24, 32, 40 Zones</h6>
                </Card.Text>
              </Card.Body>
            </Card>
            <br />
          </div>{" "}
        </div>
        <br />
        {/* e1 */}
      </div>
    </>
  );
}

export default As;
