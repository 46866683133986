import React from "react";

function ProSidebar() {
  return (
    <>
      <ul>
        <a href="">
          {" "}
          <li>Home</li>
        </a>

        <a href="">
          {" "}
          <li>Home</li>
        </a>
        <a href="">
          {" "}
          <li>Home</li>
        </a>
        <a href="">
          {" "}
          <li>Home</li>
        </a>
      </ul>
    </>
  );
}

export default ProSidebar;
