import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import N1 from "./N1";
import "../App.css";
function Abo() {
  return (
    <>
      <N1 />
      {/* <div className="mask">
        <Card>
          <Card.Img
            variant="top"
            src="./spartan1.jpg"
            style={{
              height: "400px",
              backgroundColor: "rgba(54, 57, 62, 0.5)",
            }}
          />
          <div
            className="mask"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          ></div>
        </Card>
      </div> */}
      <div className="abo">
        <div
          className="kkc"
          style={{
            textAlign: "center",
            padding: "92px",
            backgroundColor: "MediumSeaGreen",
            color: "white",
            teaxtAlign: "justify",
          }}
        >
          <h1> About UTPL </h1>
          {/* <p1>
            UTPL Technologies Pvt. Ltd. is an Indian manufacturer of advanced
            technology and products with a world-class manufacturing facility
            and an in-house research, development, and innovation team.
          </p1>
          <br></br>
          <br></br>
          <p2>
            With the support of cutting-edge infrastructure that combines
            innovative designs, an experienced development team, and
            one-of-a-kind genuine solutions that adhere to international
            industry standards, UTPL is committed to empowering the industry and
            the consumer. UTPL manufactures advance Intrusion/Burglar alarm
            panels, Sensors & Home Automation products.
          </p2> */}
        </div>
        <br></br>
        <br></br>

        <div className="container">
          <p1>
            UTPL Technologies Pvt. Ltd. is an Indian manufacturer of advanced
            technology and products with a world-class manufacturing facility
            and an in-house research, development, and innovation team.
          </p1>
          <br></br>
          <br></br>
          <p2>
            With the support of cutting-edge infrastructure that combines
            innovative designs, an experienced development team, and
            one-of-a-kind genuine solutions that adhere to international
            industry standards, UTPL is committed to empowering the industry and
            the consumer. UTPL manufactures advance Intrusion/Burglar alarm
            panels, Sensors & Home Automation products.
          </p2>
        </div>
        <div class="container">
          <br></br>
          <br></br>
          <h3>WHY SHOULD YOU USE AN INTRUSION DETECTION AND ALARM SYSTEM?</h3>
          <br></br>
          <p1>
            <b style={{ color: "#7A7A7A" }}>
              Today, our security and privacy are suffering greatly. We are
              seeing a frightening rise in the number of daily theft cases.
              Finding a safe and secure place to live has become increasingly
              difficult. Criminal burglary cases are also taking a dangerously
              brutal turn – investing in the right security solutions has never
              been as important as it is today.
            </b>
          </p1>
          <br></br>
          <br></br>
          <p2>
            {" "}
            <b style={{ color: "#7A7A7A" }}>
              UTPL’s Intruder Detection System is designed for private areas,
              restricted areas, commercial areas, and domestic home applications
              to alert the user of any intruder’s or any person’s entry into the
              specified areas. When the system detects an intruder in the area,
              it alerts the owners through notification via a registered
              application, enabling them to act in real-time and prevent theft
              and unauthorized entry into restricted areas
            </b>
          </p2>
          <br></br>
          <br></br>
          <br></br>
          <h3>Take Immediate Action</h3>
          <br></br>
          <p3>
            <b style={{ color: "#7A7A7A" }}>
              Real-time safety/Security monitoring, warning and alarm through an
              application can help you take necessary actions on time to keep
              your place safe from any criminal attacks.{" "}
            </b>
          </p3>
        </div>
        <br></br>
        <br></br>
        <div className="container">
          <h4>
            <b>
              WHY CHOOSE INTRUSION DETECTION AND ALARM SYSTEM OVER CCTV?
              <br></br>
              <span style={{ color: "green" }}>
                Give more insight and comparison on this.
              </span>{" "}
            </b>
          </h4>
          <br></br>
          <div className="row">
            <div className="col-md-6">
              <Card style={{ width: "30rem" }}>
                <Card.Body style={{ textAlign: "justify", height: "300px " }}>
                  <Card.Title>
                    UTPL’s Intrusion Detection and Alarm System
                  </Card.Title>
                  <br></br>
                  <Card.Text>
                    <ul>
                      <li>Surveillance 24x7</li>
                      <li>Real-time intrusion detection</li>
                      <li>
                        Real-time intrusion alarm and notification sent to user
                      </li>
                      <li>Mobile and Web App Integrated</li>
                      <li>IoT enabled and AI-Based</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6">
              <Card style={{ width: "30rem" }}>
                <Card.Body style={{ textAlign: "justify", height: "300px" }}>
                  <Card.Title>Any Other CCTV Surveillance System</Card.Title>
                  <br></br>
                  <Card.Text>
                    <ul>
                      <li>Surveillance 24x7</li>
                      <li>
                        No provision for real-time intrusion detection, you can
                        only check the recordings later for identification of
                        intruders
                      </li>
                      <li>
                        No provision for alerting the user of possible intrusion
                        or unauthorized access to premises
                      </li>
                      <li>Mobile and Web App Integrated</li>
                      <li>May or may not be IoT enabled and AI-Based</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <br></br>
          <br></br>
          <h4 class="ffc">
            <b>OVERCOME YOUR SECURITY CHALLENGES WITH UTPL</b>
          </h4>
          <br></br>
          <p1 style={{ color: "#7A7A7A", textAlign: "justify" }}>
            The presence of an Intrusion Detection System in your home makes
            thieves think twice about breaking into your property due to the
            nearly 100% chance of being caught. With UTPL’s Intrusion Detection
            System, you can now see what is going on around your premises with
            the mobile application. You can get an alert if there is any
            suspicious movement near the perimeter of your premises. It lowers
            the cost of deploying a large number of man guards while also
            providing better protection as an added value.
          </p1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {/* <h3
                className="ff"
                style={{ color: "#2AB33C", padding: "64px 0px" }}>
                UTPL PROFILE
              </h3> */}
              <h1
                className="uu"
                style={{
                  backgroundImage: "url(./photos/s2.jpg)",
                  height: "276px",
                  backgroundSize: "cover",
                  width: "90%",
                  backgroundRepeat: "no-repeat",
                  marginTop: "91px",
                  color: "black",
                }}
              >
                <h2
                  style={{
                    paddingTop: "80px",
                    fontSize: "23px",
                    backgroundColor: "",
                  }}
                >
                  All level of product development from product idea to field
                  implantation.
                </h2>
              </h1>
            </div>
            <div className="col-md-6">
              <h6
                className="ll"
                style={{
                  lineHeight: "1.7rem",
                  fontWeight: "500",
                  color: "#7A7A7A",
                  margin: "89px 0px",
                  textAlign: "justify",
                }}
              >
                {" "}
                UTPL Technologies blend up the latest & legacy technologies into
                innovative products by in house four horizontal wings .which
                will do the continuous product research, bring up high reliable
                value added products, provide Service and support the ecosystem
                , Involve Analysis and Field Validation for the continuous
                improvement and customer satisfaction.
                <br></br>
                <br></br>
                UTPL can under take all level of product development from
                product idea to field implantation (Single Stop). UTPL can do
                full fledge product support by Inhouse Verticals and strategic
                partners.
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Img variant="top" src="./images/Download1.jpg" />
                <Card.Body style={{ height: "163px", textAlign: "left" }}>
                  <Card.Title>
                    <b>Research</b>
                  </Card.Title>
                  <Card.Text>
                    Technology Development Feasibility Analysis
                    <br></br>New Ideas
                    <br></br> Invention and Improvement
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Img variant="top" src="./images/Download2.jpg" />
                <Card.Body style={{ height: "163px", textAlign: "left" }}>
                  <Card.Title>
                    <b>Development</b>
                  </Card.Title>
                  <Card.Text>
                    Proto type & Product Development <br></br>
                    System bring up<br></br> Optimization<br></br>
                    PCB & System Assembly
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Img variant="top" src="./images/Download3.jpg" />
                <Card.Body style={{ height: "163px", textAlign: "left" }}>
                  <Card.Title>
                    <b>Support</b>
                  </Card.Title>
                  <Card.Text>
                    <h6>Technology Solution Support</h6>
                    <h6>Diagnosis and Error Analysis</h6>
                    <h6>Installation & Field Support</h6>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3">
              <Card style={{ width: "16rem" }}>
                <Card.Img variant="top" src="./images/Download4.jpg" />
                <Card.Body style={{ height: "163px", textAlign: "left" }}>
                  <Card.Title>
                    <b>Transfer</b>
                  </Card.Title>
                  <Card.Text>
                    <h6>Value added Product</h6>
                    <h6>Implementation</h6>
                    <h6>QA Testing Validation</h6>
                    <h6>Form Fit Function &</h6>
                    <h6>Obsolesce Management</h6>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <br></br>
          <br></br>
          <h5>
            <b>SINGLE STOP FOR PRODUCT DESIGN, ENGINEERING, QUALIFICATION</b>
          </h5>
          <h5>
            <b>AND CONTRACT ELECTRONIC MANUFACTURING</b>
          </h5>
          <br></br>
          <br></br>
          <img src="./images/stock1.png" />
          <br></br>
          <br></br>
          <br></br>

          <div className="row">
            <div className="col-md-4">
              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Title>PRODUCT DESIGN</Card.Title>
                  <Card.Text>
                    <ul style={{ textAlign: "left" }}>
                      <li>Embedded</li>
                      <li>Defense</li>
                      <li>Digital</li>
                      <li>Analog</li>
                      <li>RF</li>
                      <li>Power</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Title>SYSTEM DESIGN & ARCHITECTURE</Card.Title>
                  <Card.Text>
                    <ul style={{ textAlign: "left" }}>
                      <li>MPU</li>
                      <li>MCU</li>
                      <li>High Speed PCB</li>
                      <li>FPGA</li>
                      <li>Mechanical</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card style={{ width: "18rem" }}>
                <Card.Body style={{ height: "224px" }}>
                  <Card.Title>VALUE ENGINEERING</Card.Title>
                  <Card.Text>
                    <ul style={{ textAlign: "left" }}>
                      <li>DFM/DFA</li>
                      <li>DFE</li>
                      <li>Test Systems</li>
                      <li>Quality Management</li>
                      <li>Qualification (MIL/Space)</li>
                      <li>Certifications</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-4">
              <Card style={{ width: "18rem" }}>
                <Card.Body style={{ height: "195px" }}>
                  <Card.Title>MANUFACTURING</Card.Title>
                  <Card.Text>
                    <ul style={{ textAlign: "left" }}>
                      <li>High-MIX</li>
                      <li>High value Prototype</li>
                      <li>High Volume Products</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Title>
                    SYSTEM DESIGN & ARCHITECTURE LOGISTICS{" "}
                  </Card.Title>
                  <Card.Text>
                    <ul style={{ textAlign: "left" }}>
                      <li>High-MIX</li>
                      <li>High value Prototype</li>
                      <li>High Volume Products</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <br></br>
        <div
          className="container-1"
          style={{
            backgroundColor: "green",
            padding: "44px 0px",
            margin: "45px 32px",
            borderRadius: "30px    ",
          }}
        >
          <div className="llp" style={{ color: "white" }}>
            <h1>Mission & Vision</h1>
            <br></br>
            <p>
              UTPL intend to develop Modules, Sub systems and solutions
              especially in the filed of Low
            </p>
            <br></br>
            <p>Power RF, IOT, Automation and connectivity.</p>
            <br></br>
            <p>
              Also has vertical to support , develop, ingenious and upgrade
              Defense and Aerospace products.
            </p>
            <br></br>
            <Button variant="secondary">Contact Us</Button>{" "}
          </div>
          <br></br>
        </div>
        <div className="kk">
          <h1>UTPL Management</h1>
          <br></br>
          <img src="./images/stock1.png" />
        </div>
        <br></br>
        <div className="container">
          <div className="col-md-2">
            <Card style={{ width: "0 rem", height: "0px" }}>
              <Card.Img variant="top" src="./images/kk.png" />
              <Card.Body>
                <Card.Title>Ratheesh Vikram Laila</Card.Title>
                <Card.Text style={{ color: "green" }}>Co-Founder</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-20">
            <p
              class="df"
              style={{
                margin: "0px 217px",
                textAlign: "justify",
                color: "#7A7A7A",
              }}
            >
              Mr. Ratheesh has extensive Exposure on System and Solution
              development in commercial and military-space segments with
              expertise of System design, Product Development Technology
              development. He has contribution to manufacture various Indian
              communication modules which uses Low Power RF technology. His
              initial career involved technology development of Optical network
              and Mobile interface communication products. He has Expertise in
              micro controller, micro processor, LPRF based Embedded systems
              designs and hands on experience in the area ASIC /FPGA
              Prototyping. He associated the team to develop various IOT based
              products for smart city, Smart Industry and cost effective-
              technology drive automation solutions. He involved Design and re
              engineering of critical military/space grade system and subsystems
              . He post graduated on VLSI & Embedded systems and has several
              technical paper and design releases in his credit.
            </p>
          </div>
          <br></br>
          <br></br>
          <div className="col-md-2">
            <Card style={{ width: "0 rem", height: "0px" }}>
              <Card.Img variant="top" src="./images/Ra.png" />
              <Card.Body>
                <Card.Title>Rajiv Ranjan</Card.Title>
                <Card.Text style={{ color: "green" }}>Co-Founder</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-20">
            <p
              class="df"
              style={{
                margin: "34px 217px",
                textAlign: "justify",
                color: "#7A7A7A",
              }}
            >
              Mr. Rajiv Ranjan is an industrialist and technology enthusiast,
              graduated from Moscow, Russia. He involved various strategic
              associations.Multiple successful joint venture and business
              relation establishments are in his credit. Rajiv Ranjan is
              co-founder of UTPL handling NCR region business developments along
              with defense and aerospace technology collaborations.
            </p>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="container">
          {/* <h1>
            <b>Core Team</b>
          </h1> */}
          <br></br>
          <img src="./images/stock1.png" />
          <br></br>
          <br></br>

          <Card className="bg-dark text-white">
            <Card.Img
              src="./images/WhatsApp Image 2022-11-29 at 2.17.52 AM.jpeg"
              alt="Card image"
              style={{ height: "570px" }}
            />
          </Card>
          {/* <dov className="row">
            <div className="col-md-3">
              <Card style={{ width: "15rem" }}>
                <Card.Img
                  variant="top"
                  src="./images/S1.png"
                  style={{ height: "165px" }}
                />
                <Card.Body>
                  <Card.Title>Sunil Kumar</Card.Title>
                  <Card.Text style={{ color: "green" }}>HOD – R&D</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3">
              <Card style={{ width: "15rem" }}>
                <Card.Img
                  variant="top"
                  src="./images/S2.png"
                  style={{ height: "165px" }}
                />
                <Card.Body>
                  <Card.Title>Anuraj</Card.Title>
                  <Card.Text style={{ color: "green" }}>Head – PCB</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3">
              <Card style={{ width: "15rem" }}>
                <Card.Img
                  variant="top"
                  src="./images/S3.png"
                  style={{ height: "165px" }}
                />
                <Card.Body>
                  <Card.Title>Ram Mohan</Card.Title>
                  <Card.Text style={{ color: "green" }}>
                    Head- Firmware
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3">
              <Card style={{ width: "15rem" }}>
                <Card.Img
                  variant="top"
                  src="./images/S4.png"
                  style={{ height: "165px" }}
                />
                <Card.Body>
                  <Card.Title>Gangadhar</Card.Title>
                  <Card.Text style={{ color: "green", fontSize: "15px" }}>
                    Head- Test & Proto-type
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </dov> */}
        </div>
        <br></br>
        <br></br>
        <br></br>
        {/* <div className="container">
        <h4 style={{color:"green"}}>Our Amazing Customers</h4>
        <h1><b>Our Clients</b></h1>
      </div> */}
      </div>
    </>
  );
}

export default Abo;
